import React from "react"
import Image from "gatsby-image"
import { css } from "theme-ui"
import { buildResponsiveVariant as rv, normalizeImage } from "../utils"

const CardMediaImage = ({ variant, loading, image }) => (
  
  <Image
    style={{ maxHeight: 350 }}
    fluid={image.childImageSharp.fluid}
  />
  // <Image
  //   fluid={image.childImageSharp.fluid}
  //   loading={loading}
  //   fadeIn={loading === "lazy" ? true : false}
  //   css={css({
  //     height: `full`,
  //     bg: `omegaLighter`,
  //     variant: rv(variant, "image"),
  //   })}
  // />
)
CardMediaImage.defaultProps = {
  loading: "lazy",
}

export default CardMediaImage
