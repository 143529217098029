import React from "react"
import { Box, Card, Flex } from "theme-ui"
import { columnSizeMatcher, buildResponsiveVariant as rv } from "../utils"
import Body from "./Card.Body"
import Footer from "./Card.Footer"
import Media from "./Card.Media"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import Image from "gatsby-image"

const styles = {
  card: {
    overflow: `hidden`,
    height: `full`,
    padding: "0.5rem",
  },
  content: {
    bg: "contentBg",
  },
}

const CardBase = ({ columns, onMouseOver, ...props }) => {
  
  return (
    <Box
      className="blog_card"
      sx={(columnSizeMatcher(columns), { ...styles.card })}
      onMouseOver={onMouseOver}
      onFocus={onMouseOver}
    >
      <Box
        className="single-post col hover-zoom"
        key={props.fields.slug}
        sx={styles.content}
      >
        <div className="post-image-holder">
          {props.frontmatter.image && (
            <Link style={{ boxShadow: `none` }} to={props.fields.slug}>
              <Image
                style={{ maxHeight: 350 }}
                fluid={props.frontmatter.image.childImageSharp.fluid}
                alt={props.frontmatter.title}
              />
            </Link>
          )}
        </div>
        <div className="post-info">
          <header>
            {/* <small>{props.frontmatter.category}</small> */}
            <h3 style={{ marginBottom: "0.3rem" }}>
              <Link style={{ boxShadow: `none` }} to={props.fields.slug}>
                {props.frontmatter.title}
              </Link>
            </h3>
            <small className="date-author">
              <span>
                <FontAwesomeIcon icon="calendar-alt" /> {props.frontmatter.date}
              </span>
              <span>
                <FontAwesomeIcon icon="user" /> {" "} {props.frontmatter.author}{" "}
              </span>
            </small>
          </header>
          {/* <footer>{props.frontmatter.description || props.excerpt}</footer> */}
        </div>
      </Box>
      {/*
     <Card
      variant='interactive'
      sx={{
        ...styles.card,
        variant: rv(props.variant, 'card')
      }}
    >
      <Flex
        as='article'
        sx={{
          ...styles.content,
          variant: rv(props.variant, 'content')
        }}
      >
        <Media {...props} />
        <Body {...props}>
          <Footer {...props} />
        </Body>
      </Flex>
    </Card> */}
    </Box>
  )
}

export default CardBase
