import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FAQ from "../components/faq"
import VSL from "../components/sales-pages/vsl"
import LiveChat from "../components/sales-pages/liveChat"
import { trackCustomEvent } from "../utils/analytics"
import Image from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useAuth } from "gatsby-theme-firebase"
import TestimonialSlider from "../components/testimonials"
import resultsIMG from "../../content/assets/icons/results.svg"
import skillsIMG from "../../content/assets/icons/skills.svg"
import communityIMG from "../../content/assets/icons/community.svg"
import { navigate } from "gatsby"
import RawLoginModal from "../components/sales-pages/loginModal"
import Grid from "../components/gridLayout"

import { Stack, Hero } from "@layout"
import Divider from "@components/Divider"
import CardList from "@components/CardList"

export default () => {
  const postsQuery = useStaticQuery(graphql`
    query postsQuery {
      site {
        siteMetadata {
          description
        }
      }
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { fileAbsolutePath: { regex: "/(blog)/" } }
      ) {
        nodes {
          excerpt
          id
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMM DD, YYYY")
            title
            description
            author
            category
            image {
              childImageSharp {
                fluid(maxWidth: 1920, maxHeight: 1080) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      cfw101: file(relativePath: { eq: "education/cfw101.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      first30days: file(relativePath: { eq: "education/30days.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ammp: file(relativePath: { eq: "education/ammp.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      smam: file(relativePath: { eq: "education/smam.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      consulting: file(relativePath: { eq: "education/consulting.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bbb: file(relativePath: { eq: "education/bbb.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const [toggleLogin, setToggleLogin] = React.useState(false)
  const { isLoggedIn } = useAuth()

  const posts = postsQuery.allMarkdownRemark.nodes
  const education = [
    {
      title: "Coding For the Web 101",
      thumb: postsQuery.cfw101.childImageSharp.fluid,
      link: "/education/coding-for-the-web-101",
    },
    {
      title: "First 30 Days: The Millionaire Mindset",
      thumb: postsQuery.first30days.childImageSharp.fluid,
      link: "/education/first-30-days-the-millionaire-mindset",
    },
    {
      title: "Amazon Millionaire Mentorship Program",
      thumb: postsQuery.ammp.childImageSharp.fluid,
      link: "/education/amazon-millionaire-mentorship-program",
    },
    {
      title: "Social Media Affiliate Marketing",
      thumb: postsQuery.smam.childImageSharp.fluid,
      link: "/education/social-media-affiliate-marketing",
    },
    // {
    //   title: "30 Minute Consulting Call",
    //   thumb: postsQuery.consulting.childImageSharp.fluid,
    //   link: "/education/consulting-call-with-jordan",
    // },
    // {
    //   title: "Business Builder Basics",
    //   thumb: postsQuery.bbb.childImageSharp.fluid,
    //   link: "/education",
    // },
  ]

  return (
    <Layout>
      <SEO
        title={`Millionaire Millennial: ${postsQuery.site.siteMetadata.description}`}
      />
      {toggleLogin && (
        <RawLoginModal
          setToggleLogin={setToggleLogin}
          isLoggedIn={isLoggedIn}
          course={{}}
        />
      )}
      <Stack>
        <Hero />
      </Stack>
      <Divider />
      <h1 style={{ textAlign: "center" }}>Recent Articles</h1>
      <Stack>
        <CardList
          nodes={posts}
          limit={5}
          variant="vertical-cover"
          slider
          columns={[1, 2, 3, 3]}
          autoPlay
        />
      </Stack>
      <Divider />
      <h1 style={{ textAlign: "center" }}>
        Millionaire Millennial Academy Courses
      </h1>
      <Stack>
        <p style={{ textAlign: "center", margin:"0 1rem" }}>
        All of the below courses are included in a membership to the Millionaire
        Millennial Academy ($19). Through these intensive programs you will
        learn everything you need to start making a full time income online. For more info on the M.M. Academy, <Link to="/academy">click here.</Link>
        </p>
      </Stack>
      <Stack>
        <Grid items={education} />
      </Stack>
    </Layout>
  )
}
