import React from "react"
import "../styles/education.scss"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { Box } from "theme-ui"

export default ({ items }) => {

  const styles = {
    content: {
      bg: "contentBg",
    },
  }

  return (
    <>
      <div className="flex-grid-thirds clickable">
        {items.map((item, i) => {
          return (
            <Box key={i} className="single-post col hover-zoom" sx={styles.content}>
              <Link to={item.link}>
              <div className="post-image-holder">
                {item.thumb && <Img fluid={item.thumb} />}
                </div>
                <h3>{item.title}</h3>

                {item.desc && <div className="card-footer">{item.desc}</div>}
              </Link>
            </Box>
          )
        })}
      </div>
    </>
  )
}
