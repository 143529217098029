import React from "react"
import { Link } from "gatsby"
import { Box, Heading, Button } from "theme-ui"
import Section from "@components/Section"

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    mb: 4,
    span: {
      color: `omegaDark`,
    },
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`,
    width: `5/6`,
  },
}

export default () => (
  <>
    <Section>
      <div className="tagline">
        <h1>
          <div className="pre-tagline">Discover How To Create an Online Income,</div>
          <u>Become Financially Free, Live a Fulfilling Life,</u>
        </h1>
        <h2 className="pre-tagline">And Pursue What's Most Important to You</h2>
        <div className="post-tagline">
          (Even if You Don't Know Where To Start!)
        </div>
      </div>
      <Box
        style={{ textAlign: "center", marginTop: "1rem" }}
        sx={styles.buttons}
      >
        <Button style={{ margin: 10 }} as={Link} to="/blog">
          Read Articles
        </Button>
        <Button
          style={{ margin: 10 }}
          variant="white"
          as={Link}
          to="/education"
        >
          The Academy
        </Button>
      </Box>
    </Section>
  </>
)
